import React from "react";

const Loading = () => 

    <div className="loading">

        <h2>
            loading...
        </h2>
        
    </div>

export default Loading;