import React from 'react';

const Footer = () => {

    return (
        <footer>
            <article>
                <p>&#169; Nicolai 2022</p>
            </article>
        </footer>
    );
}
export default Footer;